import { React } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { CartState } from "../../Context";
import SignInSide from "../Authentication/SignInSide";
import MiniDrawer from "../Drawer";

const ManagedRoutes = () => {
  const { admin, setAdmin } = CartState();
  console.log("admin---", admin.token);
  return (
    <Routes>
      {(admin && admin.token) || localStorage.getItem("user") ? (
        <Route path="/*" element={<MiniDrawer />} />
      ) : (
        <Route path="/login" element={<SignInSide />} />
      )}
      <Route
        path="/*"
        element={
          (admin && admin.token) || localStorage.getItem("user") ? (
            <Navigate to={"/"} replace={true} />
          ) : (
            <Navigate to={"/login"} replace={true} />
          )
        }
      />
      <Route
        path="/login"
        element={
          (admin && admin.token) || localStorage.getItem("user") ? (
            <Navigate to={"/"} replace={true} />
          ) : (
            <Navigate to={"/login"} replace={true} />
          )
        }
      />
    </Routes>
  );
};

export default ManagedRoutes;
