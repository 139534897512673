import { createContext, useContext, useState } from "react";

const Cart = createContext();

const Context = ({ children }) => {
  const [admin, setAdmin] = useState({ name: "", token: "" });

  return (
    <>
      <Cart.Provider
        value={{
          admin,
          setAdmin,
        }}
      >
        {children}
      </Cart.Provider>
    </>
  );
};

export const CartState = () => {
  return useContext(Cart);
};

export default Context;
