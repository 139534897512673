import React, { useEffect, useState } from "react";
import { Button, CircularProgress, TextField } from "@mui/material";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { successToast } from "../Constants";
import { db } from "../FirebaseConfig";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const Contact = () => {
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState();
  const [docId, setDocId] = useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleEdit = async (val) => {
    setDocId(val);
    handleOpen();
  };
  const getContactData = async () => {
    setLoad(true);
    let docs = [];
    const docRef = doc(db, "ContactData", "T8QY2vFUEQ0fxA5xuPwV");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      docs.push(docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
    setLoad(false);
    setData(docs);
  };
  console.log(data);
  useEffect(() => {
    getContactData();
  }, []);
  const handleEditSubmit = async () => {
    handleClose();
    setLoad(true);
    await updateDoc(doc(db, "ContactData", "T8QY2vFUEQ0fxA5xuPwV"), {
      [`${docId}`]: value,
    });
    getContactData();
    toast.success("🦄 Edited Succesfully !", successToast);
    setLoad(false);
  };
  return (
    <>
      {load === true ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress sx={{ marginTop: "20px" }} />
        </div>
      ) : data[0] ? (
        <div className="contactData">
          <div className="contactItem">
            <div className="contactEdit">
              <div className="contactItemHeading">Email</div>
              <EditIcon
                sx={{ fontSize: "25px", cursor: "pointer" }}
                onClick={() => handleEdit("email")}
              />
            </div>
            <div>{data[0].email}</div>
          </div>
          <div className="contactItem">
            <div className="contactEdit">
              <div className="contactItemHeading">Phone</div>
              <EditIcon
                sx={{ fontSize: "25px", cursor: "pointer" }}
                onClick={() => handleEdit("phone")}
              />
            </div>
            <div>{data[0].phone}</div>
          </div>
          <div className="contactItem">
            <div className="contactEdit">
              <div className="contactItemHeading">Open Hours</div>
              <EditIcon
                sx={{ fontSize: "25px", cursor: "pointer" }}
                onClick={() => handleEdit("time")}
              />
            </div>

            <div>{data[0].time}</div>
          </div>
          <div className="contactItem">
            <div className="contactEdit">
              <div className="contactItemHeading">Location</div>
              <EditIcon
                sx={{ fontSize: "25px", cursor: "pointer" }}
                onClick={() => handleEdit("location")}
              />
            </div>

            <div>{data[0].location}</div>
          </div>
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress sx={{ marginTop: "20px" }} />
        </div>
      )}
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <TextField
              sx={{ marginTop: "20px", width: "100%" }}
              id="outlined-basic"
              label={`Enter ${docId}`}
              variant="outlined"
              onChange={(e) => setValue(e.target.value)}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Button variant="contained" onClick={handleEditSubmit}>
                Submit
              </Button>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default Contact;
