import "./Styles/App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ManagedRoutes from "./Components/ManagedRoutes/ManagedRoutes";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/*" element={<ManagedRoutes />} />
      </Routes>
    </Router>
  );
}

export default App;
