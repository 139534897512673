import { Button, CircularProgress, TextField } from "@mui/material";
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { errorToast, successToast } from "../../Constants";
import { db } from "../../FirebaseConfig";
import Editor from "../Editor";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import parse from "html-react-parser";
import { margin } from "@mui/system";
const Blogs = () => {
  const [title, setTitle] = useState("");
  const [value, setValue] = useState("");
  const [load, setLoad] = useState(false);
  const [age, setAge] = useState("");
  const [cats, setCats] = useState([]);
  const [blogs, setBlogs] = useState([]);

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const createBlog = async () => {
    setLoad(true);
    if (value && title && age) {
      await addDoc(collection(db, "BlogPosts"), {
        title: title,
        content: value,
        category: age,
      })
        .then(async (val) => {
          await setDoc(
            doc(db, "Category", age),
            {
              blogs: arrayUnion(val.id),
            },
            { merge: true }
          );
          getBlogs();
          toast.success(`🦄 Blog Created Succesfully`, successToast);
        })
        .catch((error) => {
          console.log(error);
          toast.error(`🦄${error}`, errorToast);
        });
    }
  };
  const getBlogs = async () => {
    setLoad(true);
    const docs = [];
    const docRef = collection(db, "BlogPosts");
    const docSnap = await getDocs(docRef);
    docSnap.forEach((data) => {
      docs.push(data);
    });
    setLoad(false);
    setBlogs(docs);
  };
  const getValue = (value) => {
    setValue(value);
  };
  const getCategories = async () => {
    setLoad(true);
    const docs = [];
    const docRef = collection(db, "Category");
    const docSnap = await getDocs(docRef);
    docSnap.forEach((data) => {
      docs.push(data);
    });
    setLoad(false);
    setCats(docs);
  };
  const deleteBlog = async (id, CatId) => {
    await deleteDoc(doc(db, "BlogPosts", id))
      .then(async (val) => {
        await updateDoc(doc(db, "Category", CatId), {
          blogs: arrayRemove(id),
        });
        getBlogs();
        toast.success("🦄 Blog Deleted !", successToast);
      })
      .catch((error) => {
        toast.error(`🦄 ${error}`, errorToast);
      });
  };
  useEffect(() => {
    getCategories();
    getBlogs();
  }, []);
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-select-small">Category</InputLabel>
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            value={age}
            label="Category"
            onChange={handleChange}
          >
            {cats.map((item, index) => {
              return (
                <MenuItem key={index} value={item.id}>
                  {item.data().name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <TextField
          placeholder="Enter Blog Title"
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          sx={{ margin: "10px 0px", width: "50%" }}
        />
      </div>
      <Editor getValue={getValue} />
      <Button
        variant="contained"
        sx={{ margin: "20px 0px",display:"block" }}
        onClick={createBlog}
      >
        {" "}
        Publish Blog
      </Button>
      {load === true ? (
        <div style={{display:"flex",justifyContent:"center"}}>
        <CircularProgress sx={{ marginTop: "20px" }} />
        </div>
      ) : (
        <div className="blog_item_wrapper">
          {blogs?.map((item, index) => {
            const imageTagStr = item
              .data()
              .content.match(/<img [^>]*src="[^"]*"[^>]*>/gm);
            return (
              <div
                className="blog_item"
                key={index}
                onClick={() => {
                  deleteBlog(item.id, item.data().category);
                }}
              >
                <div className="blog_image">
                  {imageTagStr ? parse(imageTagStr[0].toString()) : null}
                </div>
                <div className="blog_title">{item.data().title}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Blogs;
