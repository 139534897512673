import { Button, CircularProgress, TextField } from "@mui/material";
import { React, useState, useEffect } from "react";
import "../../Styles/App.css";
import {
  addDoc,
  collection,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../FirebaseConfig";
import { toast } from "react-toastify";
import { errorToast, successToast } from "../../Constants";

const Categories = () => {
  const [text, setText] = useState();
  const [docsList, setDocsList] = useState([]);
  const [load, setLoad] = useState(false);
  const createCategory = async () => {
    setLoad(true);

    if (text) {
      await addDoc(collection(db, "Category"), {
        name: text,
      })
        .then((val) => {
          toast.success(`🦄 Category Created Succesfully`, successToast);
          getCategories();
          setText("");
        })
        .catch((error) => {
          toast.error(`🦄${error}`, errorToast);
        });
    }
  };
  const getText = (e) => {
    setText(e.target.value);
  };

  const getCategories = async () => {
    setLoad(true);
    const docs = [];
    const docRef = collection(db, "Category");
    const docSnap = await getDocs(docRef);
    docSnap.forEach((data) => {
      console.log(data);
      docs.push(data);
    });
    setLoad(false);
    setDocsList(docs);
  };
  useEffect(() => {
    getCategories();
  }, []);
  const deleteDocument = async (name) => {
    setLoad(true);

    await deleteDoc(doc(db, "Category", name)).then((val) => {
      getCategories();
    });
  };
  return (
    <div className="category_block">
      <div className="category">
        <TextField
          required={true}
          onChangeCapture={getText}
          placeholder="Enter Category Name"
        ></TextField>
        <Button onClick={createCategory}>Create Category</Button>
        {load === true ? (
          <CircularProgress sx={{ margin: "auto" }} />
        ) : (
          <div style={{ display: "flex" }}>
            {docsList.length !== 0
              ? docsList.map((item, index) => {
                  return (
                    <div
                      onClick={() => {
                        deleteDocument(item.id);
                      }}
                      className="category_item_wrapper"
                      key={index}
                    >
                      <div className="category_item">{item.data().name}</div>
                    </div>
                  );
                })
              : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default Categories;
