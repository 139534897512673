import { React, useState, useEffect } from "react";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  listAll,
} from "firebase/storage";
import storage from "../../FirebaseConfig";
import { Button, Input } from "@mui/material";

const Advertise = () => {
  console.log("---Advert rendered---");
  let [file, setFile] = useState(""); // progress
  let [percent, setPercent] = useState(0);
  let [image, setImage] = useState();
  var listRef = ref(storage, `/files/`);
  function handleChange(event) {
    setFile(event.target.files[0]);
  }
  const onClick = () => {
    listAll(listRef)
      .then((res) => {
        res.prefixes.forEach((folderRef) => {
          console.log("folderRef");
          console.log(folderRef);
        });
        res.items.forEach((itemRef) => {
          getDownloadURL(itemRef).then((url) => {
            console.log(`url ${url}`);
            setImage(url);
          });
          console.log(image);
        });
      })
      .catch((error) => {
        console.log("error");
        console.log(error);
      });
  };

  function handleUpload() {
    if (!file) {
      alert("Please choose a file first!");
    }

    console.log(file);

    const storageRef = ref(storage, `/files/advertisement`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        ); // update progress
        setPercent(percent);
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);
          setImage(url);
        });
      }
    );
  }

  useEffect(() => {
    onClick();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="advertise">
      <Input type="file" onChange={handleChange} accept="" />
      <Button
        variant="contained"
        onClick={handleUpload}
        sx={{ width: "50%", marginTop: "40px", marginBottom: "50px" }}
      >
        Upload to Firebase
      </Button>

      {percent === 0 ? null : <p>{percent.isNaN ? 0 : percent} % Uploaded</p>}
      {image ? <img src={image} alt="img" width={"60%"}></img> : null}
    </div>
  );
};

export default Advertise;
