import React, { useEffect, useState } from "react";
import { collection, getDocs, deleteDoc, doc } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import { CircularProgress } from "@mui/material";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const Support = () => {
  const [load, setLoad] = useState(false);
  const [data, setData] = useState();
  const getSupportData = async () => {
    setLoad(true);
    const querySnapshot = await getDocs(collection(db, "Contact"));
    let docs = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      //   console.log(doc.id, " => ", doc.data());
      docs.push(doc);
    });
    setData(docs);
    setLoad(false);
  };
  const handleDelete = async (id) => {
    setLoad(true);
    await deleteDoc(doc(db, "Contact", id));
    getSupportData();
  };
  console.log(data);
  useEffect(() => {
    getSupportData();
  }, []);

  return (
    <div>
      {load === true ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress sx={{ marginTop: "20px" }} />
        </div>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Name</StyledTableCell>
                <StyledTableCell align="center">Phone Number</StyledTableCell>
                <StyledTableCell align="center">Description</StyledTableCell>
                <StyledTableCell align="center">Remove</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {load === true ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress sx={{ marginTop: "20px" }} />
                </div>
              ) : (
                data?.map(
                  (row, index) => (
                    console.log(row.id),
                    (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="center">
                          {row.data().name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.data().phone}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.data().description}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <DeleteIcon
                            sx={{ cursor: "pointer" }}
                            onClick={() => handleDelete(row.id)}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    )
                  )
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default Support;
