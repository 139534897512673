import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Input, TextField } from "@mui/material";
import {
  arrayRemove,
  arrayUnion,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { successToast } from "../Constants";
import storage, { db } from "../FirebaseConfig";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
const About = () => {
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([]);
  const [name, setName] = useState();
  const [desi, setDesi] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  };
  const handleUpload = async () => {
    if (!selectedFile || !name || !desi) {
      alert("Please choose Something first!");
    }
    console.log(selectedFile);
    const storageRef = ref(storage, `/teams/${name}`);
    const uploadTask = uploadBytesResumable(storageRef, selectedFile);
    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);
          addAboutData(url);
        });
      }
    );
  };
  const addAboutData = async (imageUrl) => {
    setLoad(true);
    if (name && desi && imageUrl) {
      await setDoc(
        doc(db, "About", "teamNames"),
        {
          team: arrayUnion({ name: name, desi: desi, image: imageUrl }),
        },
        { merge: true }
      ).then(() => {
        setSelectedFile(null);
        setName("");
        setDesi("");
      });
      getAboutData();
      toast.success(`🦄 Member Added Succesfully`, successToast);
    }
  };
  const getAboutData = async () => {
    setLoad(true);
    let docs = [];
    const docRef = doc(db, "About", "teamNames");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      docs.push(docSnap.data());
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
    setLoad(false);
    setData(docs);
  };
  useEffect(() => {
    getAboutData();
  }, []);
  const deleteMember = async (deleteName, deleteDesi, image) => {
    await updateDoc(doc(db, "About", "teamNames"), {
      team: arrayRemove({ name: deleteName, desi: deleteDesi, image: image }),
    }).then(() => {
      const desertRef = ref(storage, `/teams/${deleteName}`);

      // Delete the file
      deleteObject(desertRef)
        .then(() => {
          // File deleted successfully
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
        });
    });
    getAboutData();
    toast.success("🦄 Member Deleted !", successToast);
  };
  return (
    <>
      <div className="about_header" id="ah">
        {selectedFile && <img src={preview} alt="" width={"20%"} />}
        <Input type="file" onChange={onSelectFile}></Input>
      </div>
      <div className="aboutName">
        <TextField
          sx={{ width: "30vw" }}
          id="outlined-basic"
          label="Name"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <TextField
          sx={{ width: "30vw", marginTop: "20px" }}
          id="outlined-basic"
          label="Designation"
          variant="outlined"
          value={desi}
          onChange={(e) => setDesi(e.target.value)}
        />
        <Button
          onClick={handleUpload}
          variant="contained"
          sx={{ marginTop: "20px" }}
        >
          Add Member
        </Button>
      </div>
      {load === true ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress sx={{ marginTop: "20px" }} />
        </div>
      ) : (
        <div className="aboutData">
          {data[0]
            ? data[0].team.map((item, index) => {
                return (
                  <div
                    className="aboutDataChild"
                    key={index}
                    onClick={() => {
                      deleteMember(item.name, item.desi, item.image);
                    }}
                  >
                    <div>
                      <img src={item.image} alt="" width={"100px"} />
                    </div>
                    <div className="name">{item.name}</div>
                    <div className="desi">{item.desi}</div>
                  </div>
                );
              })
            : null}
        </div>
      )}
    </>
  );
};

export default About;
